<template>
  <div class="pd10 print-pane">
    <div v-for="(tempInfo,index) in tempList" :key="index" ref="paneData" class="A4">
      <div class="print-header">
        <h3 class="center">{{companyName}}</h3>
        <p class="center">
          <span>天气：{{tempInfo.weather}}</span>
          <span>最高温：{{tempInfo.maxWeatherTemp}}</span>
          <span>最低温：{{tempInfo.minWeatherTemp}}</span>
          <span>风向：{{tempInfo.windDirection}}</span>
          <span>风速：{{tempInfo.windPower}}</span>
        </p>
        <p class="center">
          <span>{{tempInfo.houseName}}</span>
          <span>{{tempInfo.testingTime}}</span>
          <span v-if="printConfig.printStorehouse">仓温：{{tempInfo.storehouseTemp}}℃</span>
          <span v-if="printConfig.printStorehouse">仓湿：{{tempInfo.storehouseDampness}}%</span>
          <span v-if="printConfig.printExternal">气温：{{tempInfo.externalTemp}}℃</span>
          <span v-if="printConfig.printExternal">气湿：{{tempInfo.externalDampness}}%</span>
        </p>
      </div>
      <PaneData :tempInfo="tempInfo" :printArchives="printConfig.printArchives"/>
    </div>
  </div>
</template>

<script>
  import printJS from 'print-js'
  export default {
    components: {
      PaneData: () => import('@/components/PaneData')
    },
    data() {
      return {
        tempList: {},
        companyName: "",
        time: '',
        type: '',
        printConfig: {}
      }
    },
    mounted() {
      this.time = this.$route.query.time
      this.type = this.$route.query.type
      this.cfParamSee();
    },
    methods: {
      //获取打印数据
      cfParamSee() {
        this.$axios.Get(this.$api.cfParamSee, {}).then(res => {
          this.printConfig = res.data
          this.getPrintData()
        }).catch(() => {
          this.getPrintData()
        })
      },
      //获取打印数据
      async getPrintData() {

        let res = await this.$axios.Get(this.$api.configGrain, {})
        this.companyName = res.data
        this.$axios.Get(this.$api.printCangType + this.type, {
          "time": this.time
        }).then(res => {
          this.tempList = res.data
          this.$nextTick().then(() => {
            setTimeout(() => {
              this.printHTML(this.$refs.paneData)
            }, 500)
          })
        })
      },
      //获取元素
      elHtml(el) {
        const div = document.createElement('div')
        div.classList.add("A4")
        const els = el.querySelectorAll(".el-table")
        let printHeader = el.querySelector(".print-header")
        div.appendChild(printHeader.cloneNode(printHeader))
        els.forEach(item => {
          let item_ = item.cloneNode(item)
          let tr = item_.querySelector(".el-table__header-wrapper tr");
          let tbody = item_.querySelector(".el-table__body-wrapper tbody");
          if (tr) {
            tbody.insertBefore(tr.cloneNode(tr), tbody.querySelectorAll("tr")[0])
            tr.remove()
          }
          div.appendChild(item_)
        })
        return div
      },
      //打印
      printHTML(els) {
        const div = document.createElement('div')
        const printDOMID = 'printDOMElement'
        div.id = printDOMID
        els.forEach(item => {
          div.appendChild(this.elHtml(item))
        })

        // 将新的 DIV 添加到页面 打印后再删掉
        document.querySelector('body').appendChild(div)

        printJS({
          documentTitle: "详情打印",
          printable: printDOMID,
          type: 'html',
          scanStyles: false,
          style: (this.type !== '0' ? ' @page{size: A4 landscape;}' : '') +
            '  .A4{ page-break-before: auto; page-break-after: always;} table {width: 100%!important;} .table th > .cell { padding-left:0!important; padding-right:0!important;} .table .cell { padding-left:0!important; padding-right:0!important; } table tr td,th { border-collapse: collapse;padding: 15px;border:1px #EBEEF5 solid;text-align:center; } .center{text-align:center;} '
        })
        div.remove()
        // @page{size: A4 landscape;}
      }
    }
  }
</script>
<style scoped lang="less">
  // @page {
  // size: A4 portrait;
  /*portrait： 纵向打印,  landscape: 横向*/
  // }

  .print-header {

    p+p {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    span {
      font-size: 12px;
    }

    span+span {
      margin-left: 15px;
    }
  }

  .A4 {
    page-break-before: auto;
    page-break-after: always;
  }
</style>
